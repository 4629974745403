<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> 
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view/>
</template>

<style>

@font-face {
  font-family: 'Bilo';
  src: url('./assets/fonts/bilo.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'product_sans';
  src: url('assets/fonts/product_sans_regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'product_sans';
  src: url('assets/fonts/product_sans_bold.ttf') format('ttf');
  font-weight: bold;
  font-style: bold;
}

/* @font-face {
  font-family: 'EuclidCircularA-Medium';
  src: url('./assets/fonts/euclid_circular_a_regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: 'Euclid';
  src: url('./assets/fonts/euclid_circular_a_bold.ttf') format('ttf');
  font-weight: bold;
  font-style: bold;
} */

/* @font-face {
  font-family: "Euclid Circular";
  src: local("Euclid"),
   url(./assets/fonts/euclid_circular_a_medium.ttf) format("truetype");
} */

html{
  overflow-x: hidden;
  min-height: 100vh;
}

body{
      margin: 0;
      font-family: 'product';
      font-size: 20px;
      color: white;
      overflow-x: hidden;
      min-height: -webkit-fill-available;
}

body a{
  text-decoration: none;
  color: white;

}

body h3{
  margin-top: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  min-height: -webkit-fill-available;
}

/* 

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
