<template>
  <header class="supHeader">
    <!-- Overlay Navigation -->
      <!-- <navbar>

        <div class="container">

            <nav class="menu">
              <a href="#">
                <span class="in">
                  <span class="bar"></span>
                  <span class="bar"></span>
                  <span class="bar"></span>
                </span>
             </a>
            </nav>

            <div class="overlay">
              <div class="close">
                <div class="close-form">
                  <a href="#">
                    <span class="in">
                      <span class="alt-bar-diff"></span>
                      <span class="alt-bar"></span>
                    </span>
                  </a>
                </div>
              </div>
              <div class="menu-overlay">
                <ul>
                  <li><a href="mailto:perin.vic@supralata.com?subject=Project%20Description">Ask me everything !</a></li>
                </ul>
              </div>
            </div>

        </div>

      </navbar> -->

      <!-- Hero Section -->
      <div class="welcome">
        <div class="circle" @click="showModal()"></div>
        <div class="greetingsection" @click="showModal()">
          <img alt="search" class="logosupralata"  src="../assets/vectors/Supralatalogo.svg">
           <h1 :class="{visible: visible, visible: !visible}" id="greeting">{{ greeting }}</h1>
           <p class="bigsticker darksticker">Start</p>
        </div>
      </div>
      <!-- <div class="herowrapper">
        <div id="Hero">

          <div class="herocontainer">

            <div class="text">
              <h1 class="bigline">{{ title }}</h1>
              <h2 class="subline">{{ subtitle }}</h2>
              <img src="../assets/images/Responsive3.png" alt="" class="responsiveheader">
              <div class="herobuttons">
                <a class="herobutton" href="mailto:perin.vic@supralata.com?subject=Project%20Description">{{ buttonOne }}</a>
                <a class="herobutton buttonTwo" href="mailto:perin.vic@supralata.com?subject=Project%20Description">{{ buttonTwo }}</a>
              </div>
              <h3 class="ontime">{{ slogan }}</h3>
              <a href="#services"><img src="../assets/vectors/arrow-02.svg" alt="" width="30px" class="arrow"></a>
            </div>

          </div>

        </div>
      </div> -->
    <!-- <h1>{{ msg }}</h1>
    <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3>Installed CLI Plugins</h3>
    <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-router" target="_blank" rel="noopener">router</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-vuex" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul> -->
  <Modal :modalActive="modalActive" @close="closeModal">
      <div class="modal-content">
          <h1 class="question">What are you looking for ?</h1>
          <input type="text" ref="search" v-model="search" v-on:keyup="goToSelectedPage">
      </div>
  </Modal>
  </header>
</template>

<script>
// const worker = new Worker("../assets/threads/uiHelper.js");
const worker = new Worker(
  "../assets/threads/uiHelper.js",
  { type: "classic" },
);
import Modal from '@/components/Modal.vue';


export default {
  name: 'SupralataHeader',
  data: () => {
      return{
          greeting: "Hello",
          visible: true,
          currentIndex: 0,
          modalActive: false,
          search: ""
      }
  },
  components: {
    // SupralataHeader,
    // SupralataServices,
    // NavigationBar,
    Modal
  },
  props: {
    title: String,
    subtitle: String,
    buttonOne: String, 
    buttonTwo: String,
    slogan: String
  },
  created(){
    
  },
  mounted(){
    // this.changeheader()

    worker.onmessage = function(e) {
        // Should output: The sum of the numbers is 15
        this.greeting = e.data.greeting;
        this.visible = e.data.opacity;
        this.currentIndex = e.data.index;
        console.log(e.data);
        setTimeout(function() {
        this.changeheader()
        }, 5000)

    };

    this.changeheader()

  },
  methods: {

    changeheader(){
    
    worker.postMessage({
        "visible": this.visible,
        "currentIndex": this.currentIndex,
    });

    // this.fadeOut(greetingText, function(finished){

    //   if(finished){
    //     console.log(finished);
    //     that.fadeIn(greetingText, function(finished){

    //       if(finished){
    //       console.log(finished);
    //       that.changeheader()
    //       }

    //     });

    //   }

    // });

  },
  showModal(){

      this.search = "";

      if(!this.modalActive){
          this.modalActive = true;
      }

  },
  closeModal(){

      if(this.modalActive){
          this.modalActive = false;
      }

  },

  async goToSelectedPage(e){
      //messageContent, name, userId, documentId
      // Add a new document with a generated id.
      if (e.keyCode === 13) {
        // console.log(this.search);
        

        const goToPage = await this.$router.push(this.search);

        if(goToPage){
          console.log(goToPage);
        } else {
          console.log(goToPage);
        }
        
      }

  }

  }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html {
  overflow-x: hidden; }

.webwrapper {
  overflow-x: hidden; }

.locked-page {
  overflow-y: hidden; }

body {
  background: #133133;
  color: #BCD8D9;
  font-family: 'Lato';
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  padding: 0;
  margin: 0;
  max-width: 100%; }

.fade-in {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s; }

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.container {
  max-width: 1400px;
  margin: 0 auto;
  width: 90%; }
  @media screen and (min-width: 550px) {
    .container {
      width: 80%; } }

.container-main {
  width: 95%;
  margin: 0 auto; }

.menu {
  float: right;
  margin-top: 20px; }

a {
  text-decoration: none;
  color: #000; }

.in {
  display: inline-block; }

.bar {
  display: block;
  width: 15px;
  height: 2px;
  background-color: #ddd;
  content: '';
  margin-bottom: 3px; }

.close-form {
  position: absolute;
  right: 5%;
  top: 15px;
  opacity: 0;
  visibility: hidden;
  z-index: 99999; }
  @media screen and (min-width: 550px) {
    .close-form {
      right: 10%;
      top: 15px; } }

.close_button {
  display: inline-block;
  text-transform: uppercase;
  bottom: -4px;
  position: relative;
  font-size: .9em;
  letter-spacing: 2px;
  color: white;
  z-index: 9999; }

.alt-bar-diff {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
  display: block;
  background-color: #ddd;
  width: 15px;
  height: 1px; }

.alt-bar {
  content: '';
  display: block;
  height: 1px;
  width: 15px;
  background-color: #ddd;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.menu-overlay {
  background-color: rgba(5, 30, 31, 0.95);
  opacity: 0;
  visibility: hidden;
  width: 100%;
  position: fixed;
  top: 0;
  -webkit-transition: all, .5s;
  -o-transition: all, .5s;
  transition: all, .5s;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; }
  .menu-overlay ul {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .menu-overlay ul li {
      font-size: 1.4em;
      margin: 1em 0;
      text-align: center; }
      .menu-overlay ul li a {
        color: white;
        -webkit-transition: all, .7s;
        -o-transition: all, .7s;
        transition: all, .7s;
        letter-spacing: 2px;
        padding: 1em 0;
        display: block;
        font-size: 1.2em;
        font-family: 'Bilo', sans-serif; }
        .menu-overlay ul li a:hover {
          text-decoration: underline;
          -webkit-transition: all, .7s;
          -o-transition: all, .7s;
          transition: all, .7s; }

.is-active {
  visibility: visible;
  opacity: 1; }

header {
  width: 100%;
  color: #BCD8D9; }

.menu {
  font-size: 18px; }

a {
  text-decoration: none;
  color: #BCD8D9; }

a:hover {
  transition: color 0.6s;
  color: #4e8c8f; }

.herowrapper {
  background: url("../assets/images/Hero.jpg");
  background-size: cover;
  background-repeat: no-repeat; 
  height: 100vh;
  }

#Hero {
  background-color: rgba(5, 30, 31, 0.85);
  padding: 20px;
  height: 100%;
}

.herocontainer {
  text-align: center;
  position: relative;
  height: 100%; }

.text{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

  @media screen and (min-width: 1700px) {
    .herocontainer {
      margin-right: auto;
      width: 1050px;
      margin-left: auto; } }

h1 {
  font-size: 3em;
  line-height: 1em;
  padding-bottom: 0.3em; }
  @media screen and (max-width: 950px) {
    h1 {
      font-size: 2em;
      padding-bottom: 0.5em; } }

h2 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.8em;
  font-weight: bold;
  color: #94bfc1;
  padding-bottom: 0.3em; }
  @media screen and (max-width: 950px) {
    h2 {
      font-size: 1.2em; } }

.bigline {
  font-family: "bilo", sans-serif;
}

.subline {
  font-size: 20px;
  margin-bottom: 25px;}

.logo {
  width: 90px;
  margin-bottom: 10px; }

.responsiveheader {
  width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px; }
  @media screen and (max-width: 950px) {
    .responsiveheader {
      width: 300px; } }

.herobutton {
  font-size: 16px;
  font-weight: bold;
  font-family: inherit;
  margin-right: 1em;
  margin-top: 1em;
  padding: 10px 30px;
  background: #4e8c8f;
  color: #BCD8D9;
  border-color: #94bfc1;
  border-radius: 3px; }
  @media screen and (max-width: 950px) {
    .herobutton {
      padding: 10px 20px;}}
  .herobutton:hover {
    transition: color 0.6s;
    color: #133133;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s; }
  @media screen and (max-width: 370px) {
    .herobutton {
      margin-right: 0.2em; } 
  }

.herobuttons{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.buttonTwo{
  margin-right: 0;
}

@media screen and (max-width: 700px) {
  .herobuttons{
    flex-direction: column;
}
.herobutton{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.buttonTwo{
  margin-right: auto;
}
}

.ontime {
  padding-top: 30px;
  margin-bottom: 30px;}

  @keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  
  .arrow {
    width: 30px;
     -webkit-animation: flickerAnimation 2s infinite;
     -moz-animation: flickerAnimation 2s infinite;
     -o-animation: flickerAnimation 2s infinite;
      animation: flickerAnimation 2s infinite;
  }

  .welcome{
    height: 100vh;
    background: black;
    position: relative;
  }

  .greetingsection{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
  }

  .visible{
    opacity: 1;
    transition: 0.3s ease-in-out;
  }

  .invisible{
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  .greetingsection h1, .question{
    margin: 0;
    font-family: 'Bilo', sans-serif;
    margin-bottom: 0.1em;
    margin-top: 0.1rem;
    padding-top: 0.2em;
    font-size: 32px;
    color: white;
  }

  .logosupralata{
  width: 80px;
  }

  .bigsticker{
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 0 10px;
  font-weight: bold;
  font-size: 16px;
}

.darksticker{
  background-color: #fff;
  color: rgb(43, 1, 1);
}

.circle{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 250px;
  height: 250px;
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
}

input{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    background: transparent;
    border: 2px solid white;
    height: 50px;
    border-radius: 8px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Bilo';
    padding-left: 15px;
}

.question{
    margin-top: 50px;
}

</style>
