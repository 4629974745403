<template>
    <footer>
    <!-- <div class="social">
      <a href="https://twitter.com/supralata"><i class="icon fab fa-twitter"></i></a>
      <a href="https://www.instagram.com/supralata/"><i class="icon fab fa-instagram"></i></a>
      <a href="https://www.youtube.com/channel/UCwIVMhwaWJcR-Q605P3oydg?view_as=subscriber"><i class="icon fab fa-youtube"></i></a>
      <a href="mailto:perin.vic@supralata.com"><i class="icon fas fa-envelope"></i></a>
    </div> -->
    <p>{{mention}}</p>
    </footer>
</template>

<script>
export default {
  name: 'SupralataFooter',
  props: {
    mention: String,
  },
  created(){
    
  }
}
</script>

<style scoped>

footer {
  padding: 15px;
  background: black;
  color: #EEEEEE;
  text-align: center; }
  
footer p {
  font-family: 'Lato', sans-serif;
  font-size: 0.9rem; 
  font-weight: bold;
}


.social {
  font-size: 25px;
  letter-spacing: 6px;
  color: #EEEEEE;
  padding-bottom: 5px; }

.social a{
 margin: 0 10px 0 10px;
}
</style>