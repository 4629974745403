<template>
  <div class="home">
    <!-- <div class="login">

    </div> -->
    <!-- <NavigationBar :navigationElements="['Ask me anything']"/> -->
    <SupralataHeader title="Premium Websites" subtitle="From £999." buttonOne="Send Request" buttonTwo="Any questions ?" slogan="Delivered on time & within budget."/>
    <!-- <SupralataServices/>  -->
    <SupralataFooter mention="© supralata.com 2022 | All rights reserved"/>
  </div>
</template>

<script>
// @ is an alias to /src
import SupralataHeader from '@/components/SupralataHeader.vue'
// import SupralataServices from '@/components/SupralataServices.vue'
import SupralataFooter from '@/components/SupralataFooter.vue'
// import NavigationBar from '@/components/NavigationBar.vue'

export default {
  name: 'Home',
  components: {
    SupralataHeader,
    // SupralataServices,
    SupralataFooter
    // NavigationBar
  }
}

</script>

<style scoped>

.home{
    min-height: -webkit-fill-available;
}

</style>
